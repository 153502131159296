.Toggle-Span {
    color:white;
}
.SwitchText{
    color:black;
}
.Nav-Title{
    color:black;
    font-weight: 800;
}
.NavBar{
    position: absolute;
}
