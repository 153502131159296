.Card {
    position: relative;

    border-radius: 1rem;
    box-shadow: 5px 5px 14px -1px rgba(0,0,0,0.57);
    padding: 1rem 0rem;
    text-align:center;
    margin:1rem;
    background: white;
}

.Card:hover{
    box-shadow: 5px 5px 14px -1px rgba(0,0,0,0.80)
    ,inset 1px 1px 5px 1px rgba(0,0,0,0.29);

}

.Card h5 {
    font-size: 2rem;
    font-weight:bold;
}

.Card img {
    height:70px;
}
.background-green {
    background-color:#59e389;
}
.background-blue {
    background-color:#00FFF3;
}

.background-red {
    background-color:#e31021;
}
.background-purple {
    background-color:#d7b8de;
}

.Card-Link{
    background-color:transparent;
	border-radius:7px;
    box-shadow: 2px 2px 7px -1px rgba(0,0,0,0.80);
	display:inline-block;
	cursor:pointer;
	color:#292b2c;
	font-family:Arial;
    font-size:17px;
    margin-top:1rem;
	padding:0.5rem 6.5rem;
	text-decoration:none;
    text-shadow:0px 1px 0px #000000;

}
.Card-Link:hover {
	background: rgb(0,61,112);
    background: linear-gradient(30deg, rgba(6,255,196,0.5) 50%, rgba(199,252,255,0.5) 50%);
    color:black;
}
.Card-Link:active {
	position:relative;
	top:1px;
}
.Card-Popup {
    background: rgba(199,252,255);
    border-radius:15px;
    margin:5px;
    color:black;
    font-size:1rem;
}

li{
    display:inline;
    list-style:none;
    font-size:1rem;
}

