.CardHolder-Container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(150px, auto);

}

@media only screen and (min-width: 1000px){
    .CardHolder-Container {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media only screen and (min-width: 1320px){
    .CardHolder-Container {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media only screen and (min-width: 1640px){
    .CardHolder-Container {
        grid-template-columns: repeat(5, 1fr);
    }
}