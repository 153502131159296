.Footer{
    display:flex;
    justify-content:center;
    width:100%;
    padding-top:0.5rem;
    height:3vh;

}
.Footer P{
        font-size: 1rem;
        letter-spacing: 0.3rem;
        font-style: italic;
        color: #292b2c;
    }