.App {
  background: rgb(6,255,196);
  background: linear-gradient(30deg, rgba(6,255,196,0.5) 50%, rgba(199,252,255,0.5) 50%);
  min-height: 100vh;

  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  
}

.sweet-loading {
  background: rgba(199,252,255,0.5);
  min-height: 100vh;

  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  text-align:center;
}
.sweet-loading h1{
  font-size: 10rem;
}